import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { User } from '../types/User';
import { useNavigate, useLocation } from 'react-router-dom';
import { Popup } from '../components/Popup'; 

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  userToken: string | null;
  loginWithGoogle: (token: string, expires_in: number, user: User) => Promise<void>;
  logout: () => void;
  setUserToken: (token: string) => void;
}


const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    const storedIsAuthenticated = localStorage.getItem('isAuthenticated');
    return storedIsAuthenticated ? JSON.parse(storedIsAuthenticated) : false;
  });

  const [user, setUser] = useState<User | null>(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [userToken, setUserToken] = useState<string | null>(() => {
    return localStorage.getItem('token');
  });

  const [tokenExpiry, setTokenExpiry] = useState<number | null>(() => {
    const storedExpiry = localStorage.getItem('tokenExpiry');
    return storedExpiry ? parseInt(storedExpiry) : null;
  });

  const [popupMessage, setPopupMessage] = useState<string | null>(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const expires_in = params.get('expires_in');
    const userParam = params.get('user'); // Assuming the user info might be passed in the redirect URL
  
    if (token && expires_in && userParam) {
      try {
        const parsedUser = JSON.parse(userParam); // Wrap in try-catch block
        loginWithGoogle(token, parseInt(expires_in), parsedUser)
          .then(() => {
            // Remove the token from the URL
            navigate(location.pathname, { replace: true });
          })
          .catch(error => {
            console.error('Error during login:', error);
            setPopupMessage('You are not authorized to use this service.');
            navigate('/login'); // Redirect to login
          });
      } catch (err) {
        console.error('Failed to parse user from URL:', err);
        setPopupMessage('Invalid user information.');
        navigate('/login'); // Redirect to login
      }
    } else {
      console.log('No token found in URL');
    }
  }, [location, navigate]);
  
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (userToken && tokenExpiry) {
        const now = Math.floor(Date.now() / 1000); // Current time in Unix timestamp (seconds)
        if (now >= tokenExpiry) {
          // Token has expired, log out
          logout();
          navigate('/login');
        }
      }
    }, 60000); // Check every minute
  
    return () => clearInterval(interval);
  }, [userToken, tokenExpiry, navigate]);

  const loginWithGoogle = async (token: string, expires_in: number, user: User) => {
    try {
      setIsAuthenticated(true);
      setUser(user); // The user object now includes roles and features
      setUserToken(token);
      const expiryTime = Date.now() + expires_in * 1000;
      setTokenExpiry(expiryTime / 1000);  // Store in seconds
      localStorage.setItem('isAuthenticated', JSON.stringify(true));
      localStorage.setItem('user', JSON.stringify(user)); // Store full user data including roles and features
      localStorage.setItem('token', token);
      localStorage.setItem('tokenExpiry', (expiryTime / 1000).toString());
      navigate('/customers');
    } catch (error: any) {
      // Check if the error is a 403 unauthorized response
      if (error.message.includes('403')) {
        console.error('Login Failed: Unauthorized user', error);
        setPopupMessage('You are not authorized to use this service.');
      } else {
        console.error('Login Failed: Unknown error', error);
        setPopupMessage('An error occurred during login.');
      }
      throw new Error('Login failed');
    }
  };
  

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    setUserToken(null);
    setTokenExpiry(null);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('tokenExpiry');
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    const storedExpiry = localStorage.getItem('tokenExpiry');
    if (storedToken && storedExpiry && Date.now() < parseInt(storedExpiry) * 1000) {
      setUserToken(storedToken);
      setTokenExpiry(parseInt(storedExpiry));
    } else {
      logout();
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, userToken, loginWithGoogle, logout, setUserToken }}>
      {children}
      {popupMessage && <Popup message={popupMessage} onClose={() => setPopupMessage(null)} />}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error('useAuth must be used within an AuthProvider');
  return context;
};
