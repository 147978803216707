import React, { useState } from 'react';
import './Header.css';
import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import logo from '../../assets/images/svg/logo.svg';
import logoSm from '../../assets/images/svg/logo-sm.svg';
import logOut from '../../assets/images/svg/logout.svg';
import feedbackIconHeader from '../../assets/images/svg/feedback-bl.svg';
import botIconHeader from '../../assets/images/svg/faq-bot.svg';
import settings from '../../assets/images/svg/settings.svg';
import { FeedbackForm } from '../FeedbackForm';

export const Header: React.FC = () => {
  const { logout, user } = useAuth();
  const navigate = useNavigate();
  const [isChatBotOpen, setIsChatBotOpen] = useState(false);

  const handleLogout = () => {
      logout();
      navigate('/login');
  };

  const toggleChatBot = () => {
    setIsChatBotOpen(!isChatBotOpen);
    const messengerEl = document.querySelector('df-messenger') as HTMLElement;
    if (messengerEl) {
      messengerEl.style.display = isChatBotOpen ? 'none' : 'block';
    }
  };

  return (
      <header className="header">
        <img className='logo' src={logo} alt="Logo" />
        <img className='logo-sm' src={logoSm} alt="Logo" />
        <div className="header-navbar">
          <nav>
            {user?.features.includes('customers') && (
              <NavLink to="/customers" end={false} className={({ isActive }) => (isActive ? 'active' : '')}>
                Customers
              </NavLink>
            )}
            {user?.features.includes('agents') && (
              <NavLink to="/agents" end={false} className={({ isActive }) => (isActive ? 'active' : '')}>
                Agents
              </NavLink>
            )}
            {user?.features.includes('ask-ai') && (
              <NavLink to="/ask" className={({ isActive }) => (isActive ? 'active' : '')}>
                Ask
              </NavLink>
            )}
            {user?.features.includes('dashboard') && (
              <NavLink to="/dashboards" className={({ isActive }) => (isActive ? 'active' : '')}>
                Dashboards
              </NavLink>
            )}
          </nav>
          {user?.role === 'super-admin' && (
              <NavLink to="/admin-panel">
                <img src={settings} alt="settings" />
              </NavLink>
            )} 
          <FeedbackForm showScreenshotButton={true} className="header-feedback-icon" imageUrl={feedbackIconHeader}/>
          <button className="chatbot-toggle fixed-icon" onClick={toggleChatBot}>
            <img src={botIconHeader} alt="Chatbot" />
          </button>
          <NavLink to="/login" onClick={handleLogout}>
            <img src={logOut} alt="logout" />
          </NavLink>         
        </div>
      </header>
  );
};
