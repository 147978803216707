import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { askQuestion, clearHistory } from '../../api/ask';
import AskUI from '../../components/AskUI/AskUI';
import { AskResponse } from '../../types/AskResponse';
const AskPage: React.FC = () => {
    const { user } = useAuth();
    const [history, setHistory] = useState<AskResponse['history']>([]);
    const [error, setError] = useState<string | null>(null);
    useEffect(() => {
        if (user) {
            // Load history if available from user context or make an API call to fetch it
        }
    }, [user]);
    useEffect(() => {
        if (error) {
            const timer = setTimeout(() => {
                setError(null);
            }, 5000); // Clear error after 5 seconds
            // Clean up the timer on component unmount or when error changes
            return () => clearTimeout(timer);
        }
    }, [error]);
    const handleSend = async (prompt: string) => {
        if (!prompt.trim()) {
            alert('Please enter a question');
            return;
        }
        const userToken = localStorage.getItem('token');
        if (!userToken) {
            alert('User is not authenticated');
            return;
        }
        try {
            const result = await askQuestion(userToken, prompt);
            setHistory(result.history);
            setError(null); // Clear any previous error
        } catch (error) {
            console.error('Error asking question:', error);
            setError('Failed to process this request. Please try something else.');
        }
    };
    const handleClear = async () => {
        const userToken = localStorage.getItem('token');
        if (!userToken) {
            alert('User is not authenticated');
            return;
        }
        try {
            await clearHistory(userToken);
            setHistory([]);
            setError(null); // Clear any previous error
        } catch (error) {
            console.error('Error clearing history:', error);
            setError('Failed to clear conversation history. Please try again later.');
        }
    };
    return (
        <div>
            {error && <div className="error-message">{error}</div>}
            <AskUI history={history.map(item => ({ question: item.question, response: item.response, timestamp: item.timestamp }))} onSend={handleSend} onClear={handleClear} />
        </div>
    );
};
export default AskPage;