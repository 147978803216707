import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Header } from './components/Header';
import { CustomerProvider } from './contexts/CustomerContext';
import { useAuth } from './contexts/AuthContext';
import CustomerSearchPage from './pages/CustomerSearchPage/CustomerSearchPage';
import CustomerDetailPage from './pages/CustomerDetailPage/CustomerDetailPage';
import { AgentsSearchPage } from './pages/AgentsSearchPage/AgentsSearchPage';
import { LoginPage } from './pages/LoginPage/LoginPage';
import DashboardsPage from './pages/DashboardsPage/DashboardsPage';
import AskPage from './pages/AskPage/AskPage';
import { AgentProvider } from './contexts/AgentContext';
import { FilterProvider } from './contexts/FilterContext';
import AgentDetailPage from './pages/AgentDetailPage/AgentDetailPage';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import NotAuthorizedPage from './pages/NotAuthorizedPage/NotAuthorizedPage';
import { AdminPanelPage } from './pages/AdminPanelPage/AdminPanelPage';

const PrivateRoute: React.FC<{ children: JSX.Element; requiredFeature: string }> = ({ children, requiredFeature }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Navigate to="/login" replace />;
  }

  if (user?.features && !user.features.includes(requiredFeature)) {
    return <Navigate to="/not-authorized" replace />;
  }

  return children;
};


export const App = () => {
  const location = useLocation();

  return (
    <div className="App">
      {location.pathname !== '/login' && <Header />}
      {location.pathname !== '/login' && <Breadcrumbs />}
      <main>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/" element={<Navigate to="/customers" replace />} />

          <Route path="/customers/*" element={
            <PrivateRoute requiredFeature="customers">
              <FilterProvider>
                <CustomerProvider>
                  <CustomerSearchPage />
                </CustomerProvider>
              </FilterProvider>
            </PrivateRoute>
          } />
          <Route path="/customers/:id" element={
            <PrivateRoute requiredFeature="customers">
              <FilterProvider>
                <CustomerProvider>
                  <CustomerDetailPage />
                </CustomerProvider>
              </FilterProvider>
            </PrivateRoute>
          } />
          <Route path="/agents/*" element={
            <PrivateRoute requiredFeature="agents">
              <FilterProvider>
                <AgentProvider>
                  <AgentsSearchPage />
                </AgentProvider>
              </FilterProvider>
            </PrivateRoute>
          } />

          <Route path="/agents/:id" element={
            <PrivateRoute requiredFeature="agents">
              <FilterProvider>
                <AgentProvider>
                  <AgentDetailPage />
                </AgentProvider>
              </FilterProvider>
            </PrivateRoute>
          } />

          <Route path="/dashboards/*" element={
            <PrivateRoute requiredFeature="dashboard">
              <DashboardsPage />
            </PrivateRoute>
          } />

          <Route path="/ask" element={
            <PrivateRoute requiredFeature="ask-ai">
              <AskPage />
            </PrivateRoute>
          } />

          <Route path="/admin-panel" element={
            <PrivateRoute requiredFeature="admin-panel">
              <AdminPanelPage />
            </PrivateRoute>
          } />

          <Route path="/not-authorized" element={<NotAuthorizedPage />} />
        </Routes>
      </main>
    </div>
  );
};


export default App;

