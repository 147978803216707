import React from 'react';
import { useAgentContext } from '../../contexts/AgentContext';
import { AgentItem } from '../AgentItem';
import { Agent } from '../../types/Agent';

import './AgentList.css';
import { Pagination } from '../Pagination';
import { Loader } from '../Loader';

export const AgentList: React.FC = () => {
  const { agents, setSelectedAgent, loading, pagination, nextPage, prevPage, itemsPerPage, setItemsPerPage, searchAgents } = useAgentContext();

  const handleSelectAgent = (agent: Agent) => {
    setSelectedAgent(agent);
  };

  if (loading) {
    return <Loader />;
  }

  if (agents.length === 0) {
    return <div>No results found.</div>;
  }

  return (
    <>
      {agents.length > 0 && (
        <Pagination
          pagination={pagination}
          nextPage={nextPage}
          prevPage={prevPage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          searchItems={searchAgents}
          itemsCount={agents.length}
        />
      )}
      <ul className="agent-cards">
        {agents.map((agent) => (
          <li key={agent.agent_id} onClick={() => handleSelectAgent(agent)}>
            <AgentItem agent={agent} />
          </li>
        ))}
      </ul>
    </>
  );
};
