import React from 'react';
import { useCustomerContext } from '../../contexts/CustomerContext';
import { CustomerItem } from '../CustomerItem';
import { CustomerEntry } from '../../types/CustomerEntry';

import './CustomerList.css';
import { Pagination } from '../Pagination';
import { Loader } from '../Loader';

export const CustomerList: React.FC = () => {
  const { customers, setSelectedCustomer, loading, pagination, nextPage, prevPage, itemsPerPage, setItemsPerPage, searchCustomers } = useCustomerContext();

  const handleSelectCustomer = (customer: CustomerEntry) => {
    setSelectedCustomer(customer);
  };

  if (loading) {
    return <Loader />;
  }

  if (customers.length === 0) {
    return <div>No results found.</div>;
  }

  return (
    <>
      {customers.length > 0 && (
        <Pagination
          pagination={pagination}
          nextPage={nextPage}
          prevPage={prevPage}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          searchItems={searchCustomers}
          itemsCount={customers.length}
        />
      )}
      <ul className="patient-cards">
        {customers.map((customer) => (
          <li key={customer.uuid} onClick={() => handleSelectCustomer(customer)}>
            <CustomerItem customer={customer} />
          </li>
        ))}
      </ul>
    </>
  );
};
