import React, { useState } from 'react';
import './AskUI.css';
import ReactMarkdown from 'react-markdown';
interface ChatMessage {
    question: string;
    response: string;
    timestamp: string;  // Add timestamp field
}
interface AskUIProps {
    history: ChatMessage[];
    onSend: (question: string) => void;
    onClear: () => void;
}
const AskUI: React.FC<AskUIProps> = ({ history, onSend, onClear }) => {
    const [question, setQuestion] = useState('');
    const handleSend = () => {
        if (question.trim()) {
            onSend(question);
            setQuestion('');
        } else {
            alert('Please enter a question');
        }
    };
    return (
        <div className="ask-container">
            <div className="ask-header">
                <h2>Ask AI on (Apr 15,2024 - Jul 15,2024) snapshot</h2>
            </div>
            <div className="ask-content">
                <div className="ask-history">
                    {history.length === 0 && (
                        <div className="welcome-message">
                            Hi, I am your personal assistant. Feel free to ask me questions.
                        </div>
                    )}
                    {history.map((item, index) => (
                        <div key={index} className="ask-message">
                            <div className="ask-question">
                                <strong>Q:</strong> {item.question}
                                <div className="timestamp">{new Date(item.timestamp).toLocaleString()}</div>
                            </div>
                            <div className="ask-response">
                                <ReactMarkdown>{item.response}</ReactMarkdown>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="ask-input-container">
                    <textarea 
                        value={question} 
                        onChange={(e) => setQuestion(e.target.value)} 
                        placeholder="Ask your question here..."
                        className="ask-input"
                    />
                    <div className="ask-buttons">
                        <button onClick={handleSend} className="ask-button send-button">Send</button>
                        <button onClick={onClear} className="ask-button clear-button">Clear</button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AskUI;